import Pill from "@unlockre/components-library/dist/pill";
import styled from "styled-components";

import * as withDealStages from "@/utils/deal-room-api/deal-stage";
import type {DealStage} from "@/utils/deal-room-api/deal-stage";

type Props = {
  className?: string;
  dealStage: DealStage;
};

type StyledPillStyledProps = {
  $dealStage: DealStage;
};

const StyledPill = styled(Pill)<StyledPillStyledProps>`
  background-color: ${props => withDealStages.getStageColor(props.$dealStage)};
`;

// eslint-disable-next-line complexity
const DealStagePill = ({dealStage, ...rest}: Props) => {
  switch (dealStage) {
    case withDealStages.dealStages.evaluation:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Evaluation"
          type="information"
          variant="primary"
          {...rest}
        />
      );

    case withDealStages.dealStages.underwriting:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Underwriting"
          type="information"
          variant="primary"
          {...rest}
        />
      );

    case withDealStages.dealStages.offer:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Offer"
          type="information"
          variant="primary"
          {...rest}
        />
      );

    case withDealStages.dealStages.diligence:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Diligence"
          type="information"
          variant="primary"
          {...rest}
        />
      );

    case withDealStages.dealStages.negotiation:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Negotiation"
          type="lightBlue"
          variant="primary"
          {...rest}
        />
      );

    case withDealStages.dealStages.closing:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Closing"
          type="blue"
          variant="primary"
          {...rest}
        />
      );

    case withDealStages.dealStages.postClosing:
      return (
        <StyledPill
          $dealStage={dealStage}
          label="Post Closing"
          type="success"
          variant="primary"
          {...rest}
        />
      );

    default:
      throw new Error("Unhandled deal stage: " + dealStage);
  }
};

export default DealStagePill;
