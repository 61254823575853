import {getDaysDiff} from "@/utils/date";

const getDaysDifference = (calendarDate: Date) =>
  Math.floor(getDaysDiff(new Date(calendarDate), new Date()));

const isCalendarDayOverdue = (calendarDate: Date) => {
  const daysDifference = getDaysDifference(calendarDate);

  return daysDifference > 0;
};

export {isCalendarDayOverdue};
