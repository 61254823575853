import {getResponsiveCss} from "@unlockre/components-library/dist/theme-provider/theme";
import styled, {css} from "styled-components";

import {PrivatePageContainer} from "@/components/page";

import CalendarWidget from "./calendar-widget";
import DealsSummaryWidget from "./deals-summary-widget";
import DealsWidget from "./deals-widget";
import Header from "./header";

const StyledPrivatePageContainer = styled(PrivatePageContainer)`
  background: radial-gradient(
      40% 80% at 50% 50%,
      rgb(199, 218, 255) 0%,
      rgb(229, 241, 239) 150%,
      rgb(240, 249, 254) 200%
    )
    0% 0% / contain;
`;

const RightWidgetsContainer = styled.div`
  align-self: start;
  position: sticky;
  top: 24px;
  max-height: calc(100vh - 48px);
  gap: 24px;
  display: grid;
  grid-template-rows: 1fr minmax(0, 1fr);

  ${getResponsiveCss(
    "laptop",
    css`
      position: static;
      top: auto;
      height: auto;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      grid-template-rows: auto;
    `
  )}
`;

const WidgetsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 270px;
  gap: 24px;

  ${getResponsiveCss(
    "laptop",
    css`
      grid-template-columns: 1fr;
    `
  )}
`;

const HomeScreen = () => (
  <StyledPrivatePageContainer>
    <Header />
    <WidgetsContainer>
      <DealsWidget />
      <RightWidgetsContainer>
        <DealsSummaryWidget />
        <CalendarWidget />
      </RightWidgetsContainer>
    </WidgetsContainer>
  </StyledPrivatePageContainer>
);

export default HomeScreen;
