import {
  getColor,
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import type {ReactElement} from "react";
import styled from "styled-components";

type Props = {
  children?: ReactElement;
  className?: string;
  description: string;
  icon: ReactElement;
  title: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${getColor("blue", "400")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  margin: 16px 0 0;
  ${getTypography("body", "s", 600)};
  color: ${getColorByAlias("textPrimary")};
`;

const Paragraph = styled.p`
  margin: 8px 0 0;
  ${getTypography("body", "s")};
  color: ${getColorByAlias("textDisabled")};
  text-align: center;
`;

const EmptyMessage = ({children, description, icon, title, ...rest}: Props) => (
  <Container {...rest}>
    <IconContainer>{icon}</IconContainer>
    <Title>{title}</Title>
    <Paragraph>{description}</Paragraph>
    {children}
  </Container>
);

export default EmptyMessage;
