import camelcaseKeys from "camelcase-keys";
import styled from "styled-components";

import useStore from "@/store/use-store";
import {useDealRoomApiGet} from "@/utils/deal-room-api";
import * as withPropertyType from "@/utils/deal-room-api/property-type";

import HomeWidgetLayout from "../home-widget-layout";

import DealsSummaryChartReferences from "./deals-summary-chart-references";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const DealsSummaryWidget = () => {
  const propertyTypeFilter = useStore(state => state.propertyTypeFilter);

  const propertyType = withPropertyType.isPropertyType(propertyTypeFilter)
    ? propertyTypeFilter
    : undefined;

  const dealsStatusSummaryResponse = useDealRoomApiGet(
    "/dashboard/deals-status-summary",
    {propertyType}
  );

  const dealsStatusSummary =
    dealsStatusSummaryResponse.data?.data &&
    camelcaseKeys(dealsStatusSummaryResponse.data.data, {deep: true});

  const totalActiveDeals = dealsStatusSummary?.activeDealByStage.reduce(
    (accumulator, curValue) => accumulator + curValue.activeDeals,
    0
  );

  return (
    <HomeWidgetLayout
      description={
        dealsStatusSummary ? totalActiveDeals + " Active Deals" : "-"
      }
      isLoading={!dealsStatusSummary}
      title="Deals Summary"
    >
      {dealsStatusSummary && (
        <Container>
          <DealsSummaryChartReferences
            {...{dealsStatusSummary}}
            totalActiveDeals={totalActiveDeals}
          />
        </Container>
      )}
    </HomeWidgetLayout>
  );
};

export default DealsSummaryWidget;
