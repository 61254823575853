import {getResponsiveCss} from "@unlockre/components-library/dist/theme-provider/theme";
import styled, {css} from "styled-components";

import EmptyMessage from "@/components/empty-message";
import {CalendarEmptyIcon} from "@/components/icons";

import HomeWidgetLayout from "../home-widget-layout";

import CalendarList from "./calendar-list";
import useDashboardCalendar from "./use-dashboard-calendar";

const StyledEmptyMessage = styled(EmptyMessage)`
  margin: 74px 0;
`;

const StyledHomeWidgetLayout = styled(HomeWidgetLayout)`
  display: flex;
  flex-direction: column;

  ${getResponsiveCss(
    "laptop",
    css`
      height: 422px;
    `
  )}
`;

const CalendarWidget = () => {
  const dashboardCalendar = useDashboardCalendar();

  return (
    <StyledHomeWidgetLayout isLoading={!dashboardCalendar} title="Calendar">
      {dashboardCalendar && Object.keys(dashboardCalendar.dates).length ? (
        <CalendarList calendarDates={dashboardCalendar.dates} />
      ) : (
        <StyledEmptyMessage
          description="There are no upcoming events this week."
          icon={<CalendarEmptyIcon />}
          title="No Upcoming Events"
        />
      )}
    </StyledHomeWidgetLayout>
  );
};

export default CalendarWidget;
