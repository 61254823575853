import {
  getColor,
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import {UnstyledButton} from "@unlockre/components-library/dist/unstyled";
import camelcaseKeys from "camelcase-keys";
import colorAlpha from "color-alpha";
import {useRouter} from "next/router";
import {useMemo} from "react";
import styled from "styled-components";

import DealStagePill from "@/components/deal-stage-pill/deal-stage-pill";
import PropertyPhoto from "@/components/property-photo";
import {useDealRoomApiGet} from "@/utils/deal-room-api";
import * as withAddress from "@/utils/deal-room-api/address";

import type {DashboardDeal} from "../types";

import DealCardIcon from "./deal-card-icon";

type Props = {
  dashboardDeal: DashboardDeal;
};

const DealImageContainer = styled.div`
  height: 210px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const DealName = styled.span`
  ${getTypography("body", "m", 400)}

  color: ${getColor("blue", "800")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;

  text-align: left;
`;

const DealNameAndSummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

const DealType = styled.span`
  ${getTypography("body", "xs", 400)}

  color: ${getColor("blue", "800")};
`;

const DealTypeAndIcon = styled.div`
  align-items: end;
  display: flex;
  gap: 4px;
`;

const DealAddress = styled.span`
  ${getTypography("body", "xs", 400)}

  color: ${getColorByAlias("textSecondary")};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
`;

const DealInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const Container = styled(UnstyledButton)`
  background: ${getColorByAlias("backgroundWhite")};
  border: 1px solid transparent;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transition: all 300ms ease-out;
  translate: 0 0;

  &:hover {
    border-color: white;
    box-shadow: 8px 8px 32px 6px
      ${props => colorAlpha(getColor("blue", "900")(props), 0.2)};
    translate: 0 -2px;
  }
`;

const StyledDealStagePill = styled(DealStagePill)`
  position: absolute;
  bottom: 16px;
  left: 16px;
`;

const DealCard = ({dashboardDeal}: Props) => {
  const router = useRouter();

  const dealPropertyImageResponse = useDealRoomApiGet(
    "/deal/{deal-id}/files/{file-id}",
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dashboardDeal.deal.id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "file-id": dashboardDeal?.photo
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      skip: !dashboardDeal?.photo
    }
  );

  const dealPropertyImage = useMemo(
    () =>
      dealPropertyImageResponse.data?.data &&
      camelcaseKeys(dealPropertyImageResponse.data.data, {deep: true}),
    [dealPropertyImageResponse.data]
  );

  return (
    <Container onClick={() => router.push(`/deals/${dashboardDeal.deal.id}`)}>
      <DealInfoContainer>
        <DealTypeAndIcon>
          <DealCardIcon propertyType={dashboardDeal.type} />
          <DealType>{dashboardDeal.type}</DealType>
        </DealTypeAndIcon>
        <DealNameAndSummaryInfo>
          <DealName>{dashboardDeal.name || "Deal"} </DealName>
          <DealAddress>
            {withAddress.getFullAddress(dashboardDeal.address)}
          </DealAddress>
        </DealNameAndSummaryInfo>
      </DealInfoContainer>
      <DealImageContainer>
        <PropertyPhoto
          alt={withAddress.getFullAddress(dashboardDeal.address)}
          url={dealPropertyImage?.url}
        />
      </DealImageContainer>
      <StyledDealStagePill dealStage={dashboardDeal.deal.stage} />
    </Container>
  );
};

export default DealCard;
