import {
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import {UnstyledUl} from "@unlockre/components-library/dist/unstyled";
import styled from "styled-components";

import * as withIso8601Date from "@/utils/date/iso-8601-date";

import CalendarDayEventCard from "./calendar-day-event-card";
import * as withCalendarDate from "./calendar-day-overdue";
import formatDateToWords from "./format-date-to-words";
import type {CalendarDayEvent} from "./types";

type Props = {
  day: string;
  dayEvents: CalendarDayEvent[];
  itemPosition: number;
};

type CalendarDayInfoStyledProps = {
  $isCalendarDayOverdue?: boolean;
};

const CalendarDayEventCardContainer = styled.li`
  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const renderCalendarDayEvents = (
  {dayEvents}: Props,
  isCalendarDayOverdue: boolean
) => (
  <UnstyledUl>
    {dayEvents.map(dayEvent => (
      <CalendarDayEventCardContainer
        key={
          dayEvent.date +
          "/" +
          dayEvent.type +
          "/" +
          dayEvent.dealId +
          Math.random()
        }
      >
        <CalendarDayEventCard {...{dayEvent, isCalendarDayOverdue}} />
      </CalendarDayEventCardContainer>
    ))}
  </UnstyledUl>
);

const getCalendarColor = (isCalendarDayOverdue: boolean) =>
  isCalendarDayOverdue
    ? getColorByAlias("feedbackError")
    : getColorByAlias("accentPrimary");

const Line = styled.div<CalendarDayInfoStyledProps>`
  border: 1px solid ${props => getCalendarColor(props.$isCalendarDayOverdue)};
  border-radius: 1px;
  flex: 1;
`;

const CalendarDay = styled.span<CalendarDayInfoStyledProps>`
  ${getTypography("body", "xxs", 600)};
  color: ${props => getCalendarColor(props.$isCalendarDayOverdue)};
  margin-right: 4px;
`;

const CalendarDayTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 12px;
`;

const Container = styled.div``;

const CalendarDayInfo = (props: Props) => {
  const calendarDate = withIso8601Date.toDate(props.day);

  const isCalendarDayOverdue =
    withCalendarDate.isCalendarDayOverdue(calendarDate);

  return (
    <Container>
      {!isCalendarDayOverdue || props.itemPosition === 0 ? (
        <CalendarDayTitle>
          <CalendarDay $isCalendarDayOverdue={isCalendarDayOverdue}>
            {isCalendarDayOverdue ? "Overdue" : formatDateToWords(calendarDate)}
          </CalendarDay>
          <Line $isCalendarDayOverdue={isCalendarDayOverdue} />
        </CalendarDayTitle>
      ) : null}
      {renderCalendarDayEvents(props, isCalendarDayOverdue)}
    </Container>
  );
};

export default CalendarDayInfo;
