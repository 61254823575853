import DealsFinder from "./deals-finder";
import DealsList from "./deals-list";
import useDealsList from "./use-deals-list";

const DealsWidget = () => {
  const dashboardDeals = useDealsList();

  if (!dashboardDeals) {
    return null;
  }

  return (
    <div>
      <DealsFinder {...{dashboardDeals}} />
      <DealsList {...{dashboardDeals}} />
    </div>
  );
};

export default DealsWidget;
