import {
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import {UnstyledUl} from "@unlockre/components-library/dist/unstyled";
import styled from "styled-components";

import * as withDealStages from "@/utils/deal-room-api/deal-stage";

import DealSumaryLinearProgress from "./deal-summary-linear-progress";
import type {ActiveDealByStage, DealsStatusSummary} from "./types";

type Props = {
  dealsStatusSummary: DealsStatusSummary;
  totalActiveDeals: number;
};

const ReferenceQuantity = styled.span`
  ${getTypography("body", "s", 600)}
  color: ${getColorByAlias("textPrimary")};
`;

const ReferenceText = styled.span`
  ${getTypography("body", "s", 400)};
  color: ${getColorByAlias("textSecondary")};
  margin-right: 3px;
`;

const ReferenceItem = styled.li`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ReferenceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled(UnstyledUl)`
  width: 100%;
`;

const DealsSummaryChartReferences = ({
  dealsStatusSummary,
  totalActiveDeals
}: Props) => (
  <Container>
    {dealsStatusSummary.activeDealByStage.map(
      (activeDeal: ActiveDealByStage) => (
        <ReferenceItem key={activeDeal.stage}>
          <ReferenceInfo>
            <ReferenceText>
              {withDealStages.getName(activeDeal.stage)}
            </ReferenceText>
            <ReferenceQuantity>{activeDeal.activeDeals}</ReferenceQuantity>
          </ReferenceInfo>
          <DealSumaryLinearProgress
            activeDeals={activeDeal.activeDeals}
            totalDeals={totalActiveDeals}
          />
        </ReferenceItem>
      )
    )}
  </Container>
);

export default DealsSummaryChartReferences;
