import useStore from "@/store/use-store";
import {useDealRoomApiGet} from "@/utils/deal-room-api";
import {calendarEventTypes} from "@/utils/deal-room-api/calendar-event-type";
import * as withPropertyType from "@/utils/deal-room-api/property-type";

import type {CalendarDates} from "./types";

const dashboardCalendarTypes = [
  calendarEventTypes.report,
  calendarEventTypes.closingDate,
  calendarEventTypes.dueDiligence,
  calendarEventTypes.lease,
  calendarEventTypes.loi,
  calendarEventTypes.psa,
  calendarEventTypes.task
];

const useDashboardCalendar = () => {
  const propertyTypeFilter = useStore(state => state.propertyTypeFilter);

  const dashboardCalendarResponse = useDealRoomApiGet("/calendar/events", {
    propertyType: withPropertyType.isPropertyType(propertyTypeFilter)
      ? propertyTypeFilter
      : undefined,
    untilDays: 7,
    type: dashboardCalendarTypes.join(",")
  });

  const dashboardCalendar = dashboardCalendarResponse?.data?.data && {
    dates: Object.entries(
      dashboardCalendarResponse.data.data.dates
    ).reduce<CalendarDates>(
      (result, [day, events]) => ({
        ...result,
        [day]: events
      }),
      {}
    )
  };

  return dashboardCalendar;
};

export default useDashboardCalendar;
