import * as withDealStages from "@/utils/deal-room-api/deal-stage";

import type {DashboardDeal} from "../types";

const compareDeals = (a: DashboardDeal, b: DashboardDeal) => {
  if (withDealStages.isBefore(a.deal.stage, b.deal.stage)) {
    return -1;
  }
  if (withDealStages.isAfter(a.deal.stage, b.deal.stage)) {
    return 1;
  }
  return 0;
};

const useOrderDeals = (dashboardDeals: DashboardDeal[]) =>
  dashboardDeals?.sort(compareDeals);

export default useOrderDeals;
