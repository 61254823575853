import Button from "@unlockre/components-library/dist/button";
import PropertyFinder from "@unlockre/components-library/dist/property-finder";
import type {PropertyFinderProps} from "@unlockre/components-library/dist/property-finder";
import PropertyItem from "@unlockre/components-library/dist/property-finder/property-item";
import {
  getColor,
  getResponsiveCss
} from "@unlockre/components-library/dist/theme-provider/theme";
import {useRouter} from "next/router";
import styled, {css} from "styled-components";

import {PlusIcon} from "@/components/icons";
import * as withAddress from "@/utils/deal-room-api/address";

import type {DashboardDeal} from "../types";

import useDealsFinder from "./use-deals-finder";

type ConcretePropertyFinderProps = PropertyFinderProps<DashboardDeal>;

type RenderPropertyItem = ConcretePropertyFinderProps["renderPropertyItem"];

type Props = {
  dashboardDeals: DashboardDeal[];
};

const StyledPropertyFinder: typeof PropertyFinder = styled(PropertyFinder)`
  z-index: 1;
  flex-grow: 1;

  ${getResponsiveCss(
    "tablet",
    css`
      width: 100%;
    `
  )}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
  white-space: nowrap;
`;

const renderDealItem: RenderPropertyItem = ({
  property: dashboardDeals,
  ...rest
}) => (
  <PropertyItem
    {...rest}
    propertyAddress={withAddress.getFullAddress(dashboardDeals.address)}
    propertyName={dashboardDeals.name || "Deal"}
    propertyType={
      dashboardDeals.type === "MEDICAL" ? "NNN" : dashboardDeals.type
    }
  />
);

const DealsFinder = ({dashboardDeals}: Props) => {
  const {dealSearch, filteredDeals, onDealSearchChange, onDealSelect} =
    useDealsFinder(dashboardDeals);

  const router = useRouter();

  return (
    <Container>
      <StyledPropertyFinder
        onPropertySearchChange={onDealSearchChange}
        onPropertySelect={onDealSelect}
        placeholder="Search your deals by name and address"
        properties={filteredDeals}
        propertySearch={dealSearch}
        renderPropertyItem={renderDealItem}
      />
      <StyledButton
        onClick={() => router.push("/deals/create")}
        size="large"
        startIcon={<PlusIcon getColor={getColor("gray", "000")} size={12} />}
        variant="primary"
      >
        New Deal
      </StyledButton>
    </Container>
  );
};

export default DealsFinder;
