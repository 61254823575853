import {UnstyledUl} from "@unlockre/components-library/dist/unstyled";
import styled from "styled-components";

import CalendarDayInfo from "./calendar-day-info";
import type {CalendarDates} from "./types";

type Props = {
  calendarDates: CalendarDates;
};

const CalendarDayInfoContainer = styled.li`
  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Container = styled(UnstyledUl)`
  position: absolute;
  inset: 0 24px;
  overflow-y: auto;
`;

const CalendarList = ({calendarDates}: Props) => (
  <Container>
    {Object.entries(calendarDates).map(([day, dayEvents], index) => (
      <CalendarDayInfoContainer key={day}>
        <CalendarDayInfo {...{day, dayEvents}} itemPosition={index} />
      </CalendarDayInfoContainer>
    ))}
  </Container>
);

export default CalendarList;
