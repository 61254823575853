import {
  getColor,
  getColorByAlias
} from "@unlockre/components-library/dist/theme-provider/theme";

import type {components} from "./deal-room-api-schema";

type SchemaDealStage = NonNullable<components["schemas"]["Deal"]["stage"]>;

const dealStages = {
  evaluation: "EVALUATION",
  underwriting: "UNDERWRITING",
  offer: "OFFER",
  negotiation: "NEGOTIATION",
  diligence: "DILIGENCE",
  closing: "CLOSING",
  postClosing: "POST_CLOSING"
} as const;

type DealStage = (typeof dealStages)[keyof typeof dealStages];

const dealStagesWithTask = [dealStages.diligence, dealStages.closing];

type DealStagesWithTasks = (typeof dealStagesWithTask)[number];

const flowableDealStages = [
  dealStages.evaluation,
  dealStages.underwriting,
  dealStages.offer,
  dealStages.negotiation,
  dealStages.diligence,
  dealStages.closing,
  dealStages.postClosing
];

// eslint-disable-next-line complexity
const getName = (dealPhase: DealStage) => {
  switch (dealPhase) {
    case dealStages.evaluation:
      return "Evaluation";

    case dealStages.underwriting:
      return "Underwriting";

    case dealStages.offer:
      return "Offer";

    case dealStages.negotiation:
      return "Negotiation";

    case dealStages.diligence:
      return "Diligence";

    case dealStages.closing:
      return "Closing";

    case dealStages.postClosing:
      return "Post Closing";

    default:
      throw new Error("No phase name: " + dealPhase);
  }
};

// eslint-disable-next-line complexity
const getStageColor = (stage: DealStage) => {
  switch (stage) {
    case dealStages.evaluation:
      return getColor("lightBlue", "550");

    case dealStages.underwriting:
      return getColor("purple", "550");

    case dealStages.offer:
      return getColor("orange1", "550");

    case dealStages.negotiation:
      return getColor("pink", "550");

    case dealStages.diligence:
      return getColor("yellow", "550");

    case dealStages.closing:
      return getColorByAlias("accentPrimary");

    case dealStages.postClosing:
      return getColor("green1", "550");

    default:
      throw new Error("Unhandled deal stage: " + stage);
  }
};

const ensureAreValid = (
  dealStages: Record<string, SchemaDealStage>
): DealStage[] => Object.values(dealStages);

const isBefore = (
  isThisPhaseBefore: DealStage,
  compareToThis: DealStage
): boolean =>
  flowableDealStages.indexOf(isThisPhaseBefore) <
  flowableDealStages.indexOf(compareToThis);

const isAfter = (
  isThisPhaseAfter: DealStage,
  compareToThis: DealStage
): boolean =>
  flowableDealStages.indexOf(isThisPhaseAfter) >
  flowableDealStages.indexOf(compareToThis);

ensureAreValid(dealStages);

export type {DealStage, DealStagesWithTasks};

export {
  dealStages,
  dealStagesWithTask,
  isAfter,
  isBefore,
  getStageColor,
  getName
};
