import {useRouter} from "next/router";
import {useEffect, useState} from "react";

import * as withAddress from "@/utils/deal-room-api/address";

import type {DashboardDeal} from "../types";

const findDeals = (
  dealSearch: string,
  dashboardDeals: DashboardDeal[],
  updater: (deals: DashboardDeal[]) => unknown
) => {
  const searchTerm = dealSearch.trim().toLowerCase();

  updater(
    dashboardDeals.filter(dashboardDeal => {
      const propertyAddress = withAddress
        .getFullAddress(dashboardDeal.address)
        .toLowerCase();

      const propertyName = dashboardDeal.name?.toLowerCase();

      return (
        propertyAddress.includes(searchTerm) ||
        propertyName?.includes(searchTerm)
      );
    })
  );
};

const useDealsFinder = (deals: DashboardDeal[]) => {
  const router = useRouter();

  const [dealSearch, setDealSearch] = useState("");
  const [filteredDeals, setFilteredDeals] = useState([]);

  const onDealSelect = (dashboardDeal: DashboardDeal) =>
    router.push(`/deals/${dashboardDeal.deal.id}`);

  useEffect(() => {
    if (dealSearch !== "") {
      findDeals(dealSearch, deals, setFilteredDeals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealSearch]);

  return {
    filteredDeals,
    dealSearch,
    onDealSearchChange: setDealSearch,
    onDealSelect
  };
};

export default useDealsFinder;
