import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import useStore from "@/store/use-store";
import {useDealRoomApiGet} from "@/utils/deal-room-api";
import * as withPropertyTypeFilter from "@/utils/deal-room-api/property-type-filter";

const useDealsList = () => {
  const propertyTypeFilter = useStore(state => state.propertyTypeFilter);

  const dealsListResponse = useDealRoomApiGet("/dashboard/deals", {
    size: 200,
    filter: {
      stages: {
        operator: "IN",
        values: [
          "DILIGENCE",
          "CLOSING",
          "OFFER",
          "NEGOTIATION",
          "EVALUATION",
          "UNDERWRITING",
          "POST_CLOSING"
        ]
      },
      ...snakecaseKeys(
        withPropertyTypeFilter.getQueryFilter(propertyTypeFilter),
        {deep: true}
      )
    }
  });

  const dealsList =
    dealsListResponse.data?.data?.data &&
    camelcaseKeys(dealsListResponse.data.data.data, {deep: true});

  return dealsList;
};

export default useDealsList;
