const calendarEventTypes = {
  closingDate: "CLOSING_DATE",
  dueDiligence: "DUE_DILIGENCE",
  report: "REPORT",
  task: "TASK",
  lease: "DOCUMENT_LEASE",
  loi: "DOCUMENT_LOI",
  psa: "DOCUMENT_PSA"
} as const;

type CalendarEventType =
  (typeof calendarEventTypes)[keyof typeof calendarEventTypes];

export type {CalendarEventType};

export {calendarEventTypes};
