import {format, isToday, isTomorrow} from "date-fns";

const formatDateToWords = (date: Date) => {
  if (isToday(date)) {
    return "Today";
  }

  if (isTomorrow(date)) {
    return "Tomorrow";
  }

  return format(date, "EEEE d");
};

export default formatDateToWords;
