import * as withTheme from "@unlockre/components-library/dist/theme-provider/theme";
import styled, {css} from "styled-components";

type Props = {
  activeDeals: number;
  totalDeals: number;
};

type InlineProgressBlockStyledProps = {
  $widthOfActiveDeals?: number;
};

const backgroundRegularCss = css`
  background: ${withTheme.getColor("blue", "150")};
`;

const backgroundCompleteCss = css`
  background: ${withTheme.getColor("blue", "450")};
`;

const Container = styled.div`
  margin-top: 4px;
`;

const InlineProgressBlock = styled.div<InlineProgressBlockStyledProps>`
  height: 10px;
  flex-grow: 1;
  border-radius: 42px;
  position: relative;
  overflow: hidden;
  padding: 0 2px 0 0;

  ${backgroundRegularCss};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    ${backgroundCompleteCss}

    width: ${props =>
      props.$widthOfActiveDeals ? props.$widthOfActiveDeals : 0}%;
  }
`;

const calculatePercentage = (totalDeals: number, activeDeals: number) =>
  (activeDeals / totalDeals) * 100;

const DealSummaryLinearProgress = ({activeDeals, totalDeals}: Props) => (
  <Container>
    <InlineProgressBlock
      $widthOfActiveDeals={
        activeDeals ? calculatePercentage(totalDeals, activeDeals) : 0
      }
    />
  </Container>
);

export default DealSummaryLinearProgress;
