import {
  MultifamilyIcon,
  NNNIcon
} from "@unlockre/components-library/dist/icons";
import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";

import {propertyTypes} from "@/utils/deal-room-api/property-type";
import type {PropertyType} from "@/utils/deal-room-api/property-type";

type Props = {
  propertyType: PropertyType;
};

const iconProps = {
  getColor: getColor("blue", "800"),
  size: 16
};

const DealCardIcon = ({propertyType}: Props) => {
  switch (propertyType) {
    case propertyTypes.medical:
      return <NNNIcon {...iconProps} />;

    case propertyTypes.multifamily:
      return <MultifamilyIcon {...iconProps} />;
  }
};

export default DealCardIcon;
