import {
  getColor,
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import {UnstyledButton} from "@unlockre/components-library/dist/unstyled";
import Link from "next/link";
import styled, {css} from "styled-components";

import {calendarEventTypes} from "@/utils/deal-room-api/calendar-event-type";
import {capitalize} from "@/utils/string";

import type {CalendarDayEvent} from "./types";

type Props = {
  dayEvent: CalendarDayEvent;
  isCalendarDayOverdue?: boolean;
};

type ContainerStyledProps = {
  $isCalendarDayOverdue?: boolean;
};

const DealAddress = styled.span`
  ${getTypography("body", "xs", 400)};
  color: ${getColorByAlias("textSecondary")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 186px;
  text-align: left;
`;

const CalendarDayEventType = styled.span`
  ${getTypography("body", "s", 600)};
  color: ${getColorByAlias("textPrimary")};
  margin-bottom: 4px;
  text-align: left;
`;

const containerCss = css`
  background: ${getColor("blue", "040")};
  border-left: 4px solid ${getColor("blue", "200")};

  :hover,
  :active {
    border-left: 4px solid ${getColor("blue", "400")};
  }

  :hover {
    background: ${getColor("blue", "100")};
  }

  :active {
    background: ${getColor("blue", "150")};
  }
`;

const containerOverdueCss = css`
  background: ${getColor("red", "100")};
  border-left: 4px solid ${getColor("red", "300")};

  :hover,
  :active {
    border-left: 4px solid ${getColor("red", "400")};
  }

  :hover {
    background: ${getColor("red", "150")};
  }

  :active {
    background: ${getColor("red", "150")};
  }
`;

const Container = styled(UnstyledButton)<ContainerStyledProps>`
  ${props =>
    props.$isCalendarDayOverdue ? containerOverdueCss : containerCss};

  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px 16px 18px 14px;
  width: 100%;
`;

const getDayEventType = ({dayEvent}: Props) => {
  switch (dayEvent.type) {
    case calendarEventTypes.closingDate:
      return "Closing Date";

    case calendarEventTypes.dueDiligence:
      return "Diligence Expiration";

    case calendarEventTypes.report:
      return `${capitalize(dayEvent.title.toLocaleLowerCase())} Due`;

    case calendarEventTypes.task:
      return String(capitalize(dayEvent.title.toLocaleLowerCase()));
  }
};

const CalendarDayEventCard = (props: Props) => (
  <Link href={`/deals/${props.dayEvent.dealId}`} passHref>
    <Container $isCalendarDayOverdue={props.isCalendarDayOverdue}>
      <CalendarDayEventType>{getDayEventType(props)}</CalendarDayEventType>
      <DealAddress>
        {props.dayEvent?.propertyTitle && props.dayEvent.propertyTitle}
      </DealAddress>
    </Container>
  </Link>
);

export default CalendarDayEventCard;
