import {
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Title = styled.span`
  ${getTypography("display", "m")}
  font-weight: 300;

  color: ${getColorByAlias("accentTertiary")};
`;

const Header = () => (
  <HeaderContainer>
    <Title>Welcome to DealRoom</Title>
  </HeaderContainer>
);

export default Header;
