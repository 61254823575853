// TODO: Move this util into the utils-package
const msInOneDay = 1000 * 60 * 60 * 24;

const getDaysDiff = (date0: Date, date1: Date) => {
  const diffTime = date1.getTime() - date0.getTime();

  return diffTime / msInOneDay;
};

export default getDaysDiff;
