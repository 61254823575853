import Button from "@unlockre/components-library/dist/button";
import * as withTheme from "@unlockre/components-library/dist/theme-provider/theme";
import {UnstyledUl} from "@unlockre/components-library/dist/unstyled";
import {useRouter} from "next/router";
import styled from "styled-components";

import EmptyMessage from "@/components/empty-message";
import {CheckCircleIcon} from "@/components/icons";

import type {DashboardDeal} from "../types";

import DealCard from "./deal-card";
import useOrderDeals from "./use-order-deals";

type Props = {
  dashboardDeals: DashboardDeal[];
};

const List = styled(UnstyledUl)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 24px;
`;

const DashboardTitle = styled.h2`
  ${withTheme.getTypography("body", "l", 600)};
  color: ${withTheme.getColorByAlias("textPrimary")};
  padding: 32px 0 16px 0;
`;

const StyledEmptyMessage = styled(EmptyMessage)`
  width: 100%;
  border: 1px solid ${withTheme.getColorByAlias("backgroundPrimary")};
  background: ${withTheme.getColorByAlias("backgroundWhite")};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  padding: 32px 0;
  margin-top: 24px;
`;

const DealsList = ({dashboardDeals}: Props) => {
  const router = useRouter();

  const orderedDashboardDeals = useOrderDeals(dashboardDeals);

  const renderEmptyMessage = () => (
    <StyledEmptyMessage
      description="You can continue creating one"
      icon={<CheckCircleIcon />}
      title="It seems that you do not have deals yet"
    >
      <Button
        onClick={() => router.push("/deals/create")}
        size="xlarge"
        variant="transparent"
      >
        Create a Deal
      </Button>
    </StyledEmptyMessage>
  );

  if (!orderedDashboardDeals?.length) {
    return renderEmptyMessage();
  }

  return (
    <div>
      <DashboardTitle>My Deals</DashboardTitle>
      <List>
        {orderedDashboardDeals.map(dashboardDeal => (
          <DealCard key={dashboardDeal.deal.id} {...{dashboardDeal}} />
        ))}
      </List>
    </div>
  );
};

export default DealsList;
