import Spinner from "@unlockre/components-library/dist/spinner";
import * as withTheme from "@unlockre/components-library/dist/theme-provider/theme";
import colorAlpha from "color-alpha";
import {forwardRef} from "react";
import type {ReactElement, ReactNode} from "react";
import styled, {css} from "styled-components";

type RefElement = HTMLDivElement;

type ContentContainerStyledProps = {
  $withoutPadding?: boolean;
};

type Props = {
  children: ReactNode;
  className?: string;
  description?: string;
  headerRight?: ReactElement;
  isLoading?: boolean;
  title: string;
  withoutContentPadding?: boolean;
};

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const padding = 24;

const contentContainerPaddingCss = css`
  padding: 0 ${padding}px;
`;

const ContentContainer = styled.div<ContentContainerStyledProps>`
  ${props => !props.$withoutPadding && contentContainerPaddingCss}
  position: relative;
  flex-grow: 1;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  ${withTheme.getTypography("body", "m", 600)}
  color: ${withTheme.getColorByAlias("accentTertiary")};
`;

const Description = styled.span`
  ${withTheme.getTypography("body", "s", 600)}
  color: ${withTheme.getColorByAlias("textSecondary")};
`;

const HeaderContainer = styled.div`
  margin-bottom: 16px;
  padding: 0 ${padding}px;
`;

const Container = styled.div`
  background-color: ${withTheme.getColorByAlias("backgroundWhite")};
  border-radius: 10px;
  box-shadow: ${props =>
    "0px 4px 58px " +
    colorAlpha(withTheme.getColor("blue", "900")(props), 0.06)};
  padding: ${padding}px 0;
`;

const HomeWidgetLayout = forwardRef<RefElement, Props>(
  (
    {
      children,
      description,
      headerRight,
      isLoading,
      title,
      withoutContentPadding,
      ...rest
    },
    ref
  ) => (
    <Container {...rest} {...{ref}}>
      <HeaderContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextContainer>
        {headerRight}
      </HeaderContainer>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner size={45} />
        </SpinnerContainer>
      ) : (
        <ContentContainer $withoutPadding={withoutContentPadding}>
          {children}
        </ContentContainer>
      )}
    </Container>
  )
);

export default HomeWidgetLayout;
